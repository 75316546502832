import Icon from '@/assets/icons/events.svg';
import type { RouterRecord } from '@/types/router';
import { RouteNames } from '@/types/router';
import { UserModuleIdentifiers } from '@/types/store/user';

export const eventsRoutes: RouterRecord = {
	path: '/events',
	component: async () => import('@/layout/LayoutIndex.vue'),
	redirect: '/events',
	meta: { title: 'events', icon: Icon, group: 'modules', id: UserModuleIdentifiers.EVENTS },
	name: RouteNames.EVENTS_ROOT,
	children: [
		{
			path: '',
			component: async () => import('@/views/events/EventIndex.vue'),
			name: RouteNames.EVENTS_DEFAULT,
			meta: { title: 'events', icon: 'events', root: true },
			children: [
				{
					path: 'locality/:locality_id(\\d+)',
					component: async () => import('@/views/events/EventIndex.vue'),
					name: RouteNames.EVENTS_LOCALITY,
				},
			],
		},
		{
			path: ':event_id',
			component: async () => import('@/views/events/EventFormRoot.vue'),
			name: RouteNames.EVENTS_EDIT_FORM,
			meta: { edit: true },
		},
		{
			path: ':event_id/turnstiles',
			component: async () => import('@/views/events/EventTurnstiles.vue'),
			name: RouteNames.EVENTS_TURNSTILES,
		},
		{
			path: 'locality/:locality_id(\\d+)/create',
			component: async () => import('@/views/events/EventFormRoot.vue'),
			name: RouteNames.EVENTS_ADD_FORM,
			meta: { edit: false },
		},
		{
			path: 'create',
			component: async () => import('@/views/events/EventFormRoot.vue'),
			name: RouteNames.EVENTS_ROOT_ADD_FORM,
			meta: { edit: false },
		},
		{
			path: 'shared',
			component: async () => import('@/views/events/EventIndex.vue'),
			name: RouteNames.EVENTS_SHARED,
			meta: { cantEnterRoles: ['superadmin'] },
		},
	],
};

